import axios from "axios";

import Navbar from './Navbar'
import Topbar from './Topbar'

import {useState, useEffect} from 'react'
import { Link, useNavigate, useLocation} from 'react-router-dom';
import {APIURL} from './config'

function Oportunidades() {

    const navigate = useNavigate()
    const locate = useLocation()

    let mycodigoempresapai

    if (!locate.state) {
        //é para a empresa logada
        mycodigoempresapai = sessionStorage.getItem("codigoempresa")
    } else {
        //é para uma revenda da empresa logada
        mycodigoempresapai = locate.state.codigoempresapai
    }

    const [results, setResults] = useState()
    const [resultsoportunidades, setResultsoportunidades] = useState()


    useEffect(() => {
        if (!sessionStorage.getItem("codigoempresa")) {
            navigate('/login')
        }
        listresume()
        getdadosempresa()
    }, [])

    const listresume= async () => {
        
        try {
           const res = await axios.post(`${APIURL}/listoportunidades.php`, {codigoempresa : mycodigoempresapai});
           if (res.data[0].ok) {
                setResultsoportunidades(res.data[0].conjuntofinal);

            } else {
                if (res.data[0].msg == 3) {
                    alert("Erro na listagem de oportunidades " + res.data[0].msg)
                }
            }
            
        } catch (err) {
            alert(err + " lista de oportunidades");
        }
    }  

    const getdadosempresa= async () => {
        
        try {
           const res = await axios.post(`${APIURL}/getempresa.php`, {codigoempresa : mycodigoempresapai});
           if (res.data[0].ok) {
           
                setResults(res.data[0].conjuntofinal[0]);
            } else {
                if (res.data[0].msg == 3) {
                    alert("Erro na listagem de dados da empresa " + res.data[0].msg)
                }
            }
            
        } catch (err) {
            alert(err + " lista de dados da empresa");
        }
    }  

      

    function formatDate1(date) {
        
        const d = new Date(date);

        return [
            d.getDate(),
            d.getMonth() + 1,
            d.getFullYear(),
        ].join('/');
    }

    
    return (

        <div>

           <Navbar />

            <div >

                
                <div className="row  white-bg" style={{minHeight: '100%', padding: 30}}>
                    <div className="container" >
                        <div className="row  mb-4 " >
                            <div className="col-12  ">
                                <h1 className="">{results && results.nomeempresa} - Oportunidades</h1>

                                <Link to="/novaoportunidade"><button className="btn btn-xs fundoverde corbranco" style={{marginTop: 10, alignItems: 'center', padding:5}}><i className=" fa fa-plus-circle"></i> Nova Oportunidade </button></Link>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Empresa</th>
                                            <th>Último Status</th>
                                            <th>Definido em</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                
                                        {resultsoportunidades &&  resultsoportunidades.map((item, i) => {

                                            
                                            return(
                                                <tr key={item.oportunidadeid}>
                                                    <td><Link to="/oportunidadeunica" state={{codigooportunidade: item.oportunidadeid}}>{item.nomeempresa}</Link></td>
                                                    <td>{item.status}</td>
                                                    <td>{formatDate1(item.data)}</td>
                                                                
                                                </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>
                
            </div>

        </div>
      );
    }
    
    export default Oportunidades;
