import axios from "axios";

import {useState, useEffect} from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {APIURL} from './config'

import Navbar from './Navbar'
import Topbar from './Topbar'
import Status from './Status'
import Produtos from './Produtos'


function OportunidadeUnica() {

    const navigate = useNavigate()
    const locate = useLocation()

    const    mycodigooportunidade = locate.state.codigooportunidade
        
    const [results, setResults] = useState()
    const [produtos, setProdutos] = useState()
    

    const [nomeempresa, setNome] = useState()
    const [cnpj, setCNPJ] = useState()
    const [responsavel, setResponsavel] = useState()
    const [telefone, setTelefone] = useState()
    const [email, setEmail] = useState()
    const [departamento, setDepartamento] = useState()
    const [obs, setObs] = useState()
    const [produto, setProduto] = useState()
    const [quantidade, setQuantidade] = useState()
    const [precounitario, setPrecoUnitario] = useState()
    const [status, setStatus] = useState()


    useEffect(() => {
        if (!sessionStorage.getItem("codigoempresa"))  {
            navigate('/login')
        }
        listresume()
        getProdutos()
        getStatus()
    }, [])

    const listresume = async () => {
        
        try {
            //alert(mycodigooportunidade)
            const res = await axios.post(`${APIURL}/listoportunidadeunica.php`, {codigooportunidade : mycodigooportunidade});
           if (res.data[0].ok) {
               setResults(res.data[0].conjuntofinal[0]);
               setNome(res.data[0].conjuntofinal[0].nomeempresa)
               setCNPJ(res.data[0].conjuntofinal[0].cnpj)
               setResponsavel(res.data[0].conjuntofinal[0].nomeusuario)
               setTelefone(res.data[0].conjuntofinal[0].telefone)
               setDepartamento(res.data[0].conjuntofinal[0].departamento)
               setEmail(res.data[0].conjuntofinal[0].email)
               setObs(res.data[0].conjuntofinal[0].obs)
             
            } else {
                if (res.data[0].msg === 3) {
                    alert("Erro nos dados da oportunidade")
                }
            }
            
        } catch (err) {
            alert(err + " oportunidade única");
        }
    }  


    const getProdutos = async () => {
        
        try {
            const res = await axios.post(`${APIURL}/getprodutos.php`, {codigooportunidade : mycodigooportunidade});
           if (res.data[0].ok) {
               setProdutos(res.data[0].conjuntofinal)
            } else {
                if (res.data[0].msg === 3) {
                    alert("Erro na listagem de produtos")
                }
            }
            
        } catch (err) {
            alert(err + " lista produtos");
        }
    }  

    const getStatus = async () => {
        
        try {
            const res = await axios.post(`${APIURL}/getstatus.php`, {codigooportunidade : mycodigooportunidade});
           if (res.data[0].ok) {
               setStatus(res.data[0].conjuntofinal)
            } else {
                if (res.data[0].msg === 3) {
                    alert("Erro na listagem de produtos")
                }
            }
            
        } catch (err) {
            alert(err + " lista produtos");
        }
    }  

    const excluiproduto = async (num) => {
        alert(num)
        try {
            const res = await axios.post(`${APIURL}/excluiprodutooportunidade.php`, {codigoprodutooportunidade : num});
           if (res.data[0].ok) {
               getProdutos()
            } else {
                if (res.data[0].msg === 3) {
                    alert("Erro na exclusão de produtos")
                }
            }
            
        } catch (err) {
            alert(err + " exclui produtos");
        }
    }  

    const  doSend = async (e) => {
        e.preventDefault()
        try {
            //alert(mycodigooportunidade + nomeempresa + responsavel + email + telefone + departamento + obs)
            if (!nomeempresa || !responsavel || !email || !telefone || !departamento ) {
                alert("Faltam dados!")
                return
            }
            const res = await axios.post(`${APIURL}/alteraoportunidadeunica.php`, {codigooportunidade: mycodigooportunidade, nomeempresa : nomeempresa, responsavel: responsavel, email: email, telefone: telefone, departamento: departamento, obs: obs})
            if (res.data[0].ok) {
                alert("Alteração feita com sucesso!")
                navigate(-1)
            } else {
                if (res.data[0].msg == 3) {
                    alert("Erro ao alterar oportunidade!")
                }
            }
        }
        catch(err){
            alert(err + " altera oportunidade ")
        }

    }

    const excluioportunidade = async (e) => {
        e.preventDefault()
        alert(mycodigooportunidade)
        try {
            const res = await axios.post(`${APIURL}/excluioportunidadeunica3.php`, {codigooportunidade : mycodigooportunidade});
           if (res.data[0].ok) {
                alert("Oportunidade excluída")
                navigate(-1)
            } else {
                if (res.data[0].msg === 3) {
                    alert("Erro na exclusão de oportunidade")
                }
            }
            
        } catch (err) {
            alert(err + " exclui oportunidade");
        }
    }

    function formatDateBR(date) {
        const d = new Date(date);
        return [
            d.getDate(),
            d.getMonth() + 1,
            d.getFullYear(),
        ].join('/');
    }


  return (
    <div >
        <Navbar />
        <div >
            <div className="row white-bg" style={{height: '100%',   padding: 30, }}>
                <div className="container" >
                    <div className="row  " >
                        <div className="col-10 mb-4" >
                            <h1>{results && results.nomeempresa} - Oportunidade</h1>
                        </div>
                        <div className="col-2 " >
                        <button onClick={(e)=> excluioportunidade(e)} className="btn btn-xs fundoverde corbranco pull-right m-t-n-xs" type="submit"><i className=" fa fa-trash"></i> </button>
                        </div>
                    </div>

                    <div className="row mb-4 " >
                        <div className="col-12" >

                            <div>
                                <ul className="nav nav-tabs mb-4" role="tablist">
                                    <li  class="nav-item"  >
                                        <a class="nav-link active" id="tab-1-tab" href="#tab-1" data-toggle="tab"  role="tab" aria-controls="tab-1" aria-selected="true">Dados</a>
                                    </li>
                                    <li  class="nav-item">
                                        <a class="nav-link " id="tab-2-tab" href="#tab-2" data-toggle="tab"  role="tab" aria-controls="tab-2" aria-selected="false">Contato</a>
                                    </li>
                                    <li class="nav-item" >
                                        <a class="nav-link "  id="tab-3-tab" href="#tab-3" data-toggle="tab"  role="tab" aria-controls="tab-3" aria-selected="false">Status</a>
                                    </li>
                                    <li class="nav-item" >
                                        <a class="nav-link " id="tab-4-tab" href="#tab-4" data-toggle="tab"  role="tab" aria-controls="tab-4" aria-selected="false">Itens</a>
                                    </li>
                                </ul>
                            </div>
                           

                            {/* <div className="tabs-content"> */}
                                <div className="tab-content">
                                    <div id="tab-1" className="tab-pane active" role="tabpanel" aria-labelledby="tab-1-tab">
                                        <div className="row mb-4 " >
                                        <div className="col-12" >

                                            <div className="form-group">
                                                <label>Nome da Empresa (*)</label> 
                                                <input type="text" value={nomeempresa} className="form-control" onChange={(e)=> setNome(e.target.value)}/>
                                            </div>

                                            <div className="form-group">
                                                    <label>CNPJ </label> 
                                                    <input  value={cnpj}  type="text"  className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label>Observação</label> 
                                                <textarea  rows={6}  value={obs}  className="form-control" onChange={(e)=> setObs(e.target.value)}/>
                                            </div>

                                            <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'space-between', marginTop: 40}}>
                                                <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'end'}}>
                                                <button onClick={() => navigate(-1)} style={{marginRight: 20}} className="btn btn-lg btn-outline pull-right m-t-n-xs" ><strong>Voltar</strong></button>
                                                <button onClick={(e)=> doSend(e)} className="btn btn-lg fundoverde corbranco pull-right m-t-n-xs" type="submit"><strong>Alterar</strong></button>
                                                </div>
                                                
                                            </div>

                                        </div>
                                        </div>
                                    </div>

                                    <div id="tab-2" className="tab-pane " role="tabpanel" aria-labelledby="tab-2-tab">
                                        <div className="panel-body">
                                            
                                            <div className="form-group">
                                                    <label>Nome do Contato Principal (*)</label> 
                                                    <input   value={responsavel} type="text"  className="form-control" onChange={(e)=> setResponsavel(e.target.value)}/>
                                            </div>

                                            <div className="form-group">
                                                    <label>Email do Contato Principal (*)</label> 
                                                    <input  type="text"  value={email}  className="form-control" onChange={(e)=> setEmail(e.target.value)}/>
                                            </div>

                                            <div className="form-group">
                                                    <label>Telefone do Contato Principal (*)</label> 
                                                    <input  type="text"  value={telefone}  className="form-control" onChange={(e)=> setTelefone(e.target.value)}/>
                                            </div>

                                            <div className="form-group">
                                                    <label>Departamento do Contato Principal (*)</label> 
                                                    <input  type="text" value={departamento}  className="form-control" onChange={(e)=> setDepartamento(e.target.value)}/>
                                            </div>

                                            <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'space-between', marginTop: 40}}>
                                                <button onClick={(e)=> excluioportunidade(results.oportunidade_id)} className="btn btn-lg fundoverde corbranco pull-right m-t-n-xs" type="submit"><strong>Excluir</strong></button>
                                                <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'end'}}>
                                                    <button onClick={() => navigate(-1)} style={{marginRight: 20}} className="btn btn-lg btn-outline pull-right m-t-n-xs" ><strong>Voltar</strong></button>
                                                    <button onClick={(e)=> doSend(e)} className="btn btn-lg fundoverde corbranco pull-right m-t-n-xs" type="submit"><strong>Alterar</strong></button>
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                    </div>


                                    <div id="tab-3" className="tab-pane " role="tabpanel" aria-labelledby="tab-3-tab">
                                        <div className="panel-body">
                                            <div className="form-group" > 
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Status</th>
                                                        <th>Data da Mudança</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {status && status.map((item, i) => {
                                                        return(
                                                            <tr key={item.statusid}>
                                                                <td>{item.status}</td>
                                                                <td>{formatDateBR(item.datamudanca)}</td>
                                                            
                                                            </tr>
                                                            )
                                                        })
                                                    }
                                                
                                                </tbody>
                                            </table>
                                            <Link to="/novostatusoportunidade" state={{codigooportunidade: mycodigooportunidade}}><button className="btn btn-lg fundoverde corbranco pull-right m-t-n-xs" type="submit"><strong>Incluir Status</strong></button></Link>
                                            </div>

                                            
                                        </div>
                                    </div>
                                    <div id="tab-4" className="tab-pane " role="tabpanel" aria-labelledby="tab-4-tab">
                                    <div style={{overflow: "auto"}}>
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>Produto</th>
                                                        <th>Qtde</th>
                                                        <th>Preço<br/>Un.</th>
                                                        <th>Excluir</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {produtos && produtos.map((item, i) => {
                                                        return(
                                                            <tr key={item.produtooportunidadeid}>
                                                                <td>{item.nomeproduto}</td>
                                                                <td>{item.quantidade}</td>
                                                                <td>{Number(item.precounitario).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                                                <td><button onClick={()=>excluiproduto(item.produtooportunidadeid)} className="btn btn-xs btn-outline fundoverde corbranco" ><i className=" fa fa-trash"></i> </button></td>
                                                                
                                                            </tr>
                                                            )
                                                        })
                                                    }
                                                
                                                </tbody>
                                            </table>
                                            <Link to="/novoprodutooportunidade" state={{codigooportunidade: mycodigooportunidade}}><button className="btn btn-lg fundoverde corbranco pull-right m-t-n-xs" type="submit"><strong>Incluir Produto</strong></button></Link>
                                                
                                        </div>
                                    </div>
                                </div> 


                            </div>
                        </div>
                    {/* </div> */}

                    


                </div>
            </div>
        </div>
    </div>
  );
}

export default OportunidadeUnica;