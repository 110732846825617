
import { Outlet } from 'react-router-dom';


function App() {
  return (
    // o Outlet mostra a rota filha do componente
    <div>
    
     <Outlet />
     </div>
  );
}

export default App;
